import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/logo_gdy.svg'
import _imports_1 from '../assets/icon/home/phone.svg'
import _imports_2 from '../assets/imgs/home/gdy_dy.svg'


const _hoisted_1 = { style: {"display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { style: {"display":"flex","justify-content":"center","text-align":"center"} }

import { ref } from 'vue';
import Home from '../view/Home.vue';
import ProPrice from '../view/ProPrice.vue';
import UpdateList from '../view/UpdateList.vue';
import DownloadFile from '../view/DownloadFile.vue';
import LinkUs from '../view/LinkUs.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const i = ref(1);
const selectedKeys = ref<string[]>(['1']);
function changeFun(v){
  i.value = v
}
function goToSys(){
  window.open("https://www.gdyapp.com/eshop", '_blank');
}

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_header, { style: { position: 'fixed', zIndex: 1, width: '100%',background:'#fff'} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[16] || (_cache[16] = _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                style: {"height":"40px"}
              }, null, -1)),
              _createVNode(_component_a_menu, {
                selectedKeys: selectedKeys.value,
                "onUpdate:selectedKeys": _cache[5] || (_cache[5] = ($event: any) => ((selectedKeys).value = $event)),
                mode: "horizontal",
                style: { lineHeight: '64px' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, {
                    key: "1",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (changeFun(1)))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("产品功能")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, {
                    key: "2",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (changeFun(2)))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("产品价格")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, {
                    key: "3",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (changeFun(3)))
                  }, {
                    default: _withCtx(() => [
                      _cache[9] || (_cache[9] = _createTextVNode("动态 ")),
                      _createVNode(_component_a_badge, { dot: "" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, {
                    key: "4",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (changeFun(4)))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("下载中心")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, {
                    key: "5",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (changeFun(5)))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("关于我们")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selectedKeys"]),
              _createElementVNode("div", _hoisted_3, [
                _cache[13] || (_cache[13] = _createTextVNode(" 一款只做商用车的综合管理软件     ")),
                _cache[14] || (_cache[14] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                _cache[15] || (_cache[15] = _createElementVNode("div", null, "   18068252929   ", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_a_button, {
                    style: {"background-color":"#1F39A9","color":"#fff","font-size":"14px","font-weight":"800"},
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (goToSys()))
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("登录")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout_content, { style: { padding: '0px 0px', marginTop: '64px' } }, {
        default: _withCtx(() => [
          (i.value==1)
            ? (_openBlock(), _createBlock(Home, { key: 0 }))
            : _createCommentVNode("", true),
          (i.value==2)
            ? (_openBlock(), _createBlock(ProPrice, { key: 1 }))
            : _createCommentVNode("", true),
          (i.value==3)
            ? (_openBlock(), _createBlock(UpdateList, { key: 2 }))
            : _createCommentVNode("", true),
          (i.value==4)
            ? (_openBlock(), _createBlock(DownloadFile, { key: 3 }))
            : _createCommentVNode("", true),
          (i.value==5)
            ? (_openBlock(), _createBlock(LinkUs, { key: 4 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout_footer, { style: {"display":"flex","justify-content":"center","align-items":"center"} }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [
          _createElementVNode("div", { style: {"width":"80%","color":"#fff","height":"130px","text-align":"center","font-size":"13px","font-weight":"700"} }, [
            _createElementVNode("div", { style: {"float":"left","margin-top":"15px","text-align":"left"} }, [
              _createElementVNode("p", null, "谷得一软件科技无锡有限公司"),
              _createElementVNode("p", null, "咨询电话：18068252929"),
              _createElementVNode("p", null, "地址：无锡市梁溪区崇安寺街道中山路333号-12G"),
              _createElementVNode("p", null, "2023 - 2024 商车E铺版权所有 | 苏ICP备2023020209号-1 ")
            ]),
            _createElementVNode("div", { style: {"float":"right","margin-top":"18px"} }, [
              _createElementVNode("p", null, [
                _createElementVNode("img", {
                  src: _imports_2,
                  height: "70px"
                })
              ]),
              _createElementVNode("p", null, "扫一扫·关注抖音")
            ])
          ], -1)
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})